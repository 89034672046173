



































import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import RichTextObject from '@/components/RichTextObject.vue';

@Component({
    components:{
        RichTextObject
    }
})
export default class Post extends Vue {
    data: any | null = null;
    goTo404Page(){ 
        this.$router.push('/not-found');
    }

    filenameOnly(filename: string){
        const splitValues = filename.split('/');
        if (!splitValues.length){
            return '';
        }
        return splitValues[splitValues.length-1];

    }

    async mounted(){ 
        let slug = this.$route.params.slug;
        

        if (!slug){ 
            slug = 'home';
        }
        
        try{
            this.data = await this.$store.dispatch('getPost', slug);

            bus.$emit('setHeader',{
                title: null, 
                subtitle: null,
                buttons: null,
                bgVideo: null
            })
        }catch(err){ 
            this.goTo404Page();
        }

        if (!this.data){
            this.goTo404Page();
            return;
        }
    }
    
}
